import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useState } from 'react'

const RadioQuestion = ({ qn, updCurrent, handleNotifications }) => {
    const [value, setValue] = useState(null)
    const [error, setError] = useState(false)
    const [helperText, setHelperText] = useState('')

    const handleChange = (event) => {
        setValue(event.target.value)
        setHelperText(' ');
        setError(false);
    }

    const prev = () => {
        updCurrent('prev')
        handleNotifications('remove', qn.id, null)
    }
    const next = () => {
        if(!value) {
            setHelperText('Valitse jokin vaihtoehdoista!')
            setError(true)
            return
        }
        const target = qn.options.find(option => option.value === value)
        if(target.notification) {
            handleNotifications('add', qn.id, target.notification)
        } else {
            handleNotifications('remove', qn.id, null)
        }
        updCurrent(target.next)
    }

    return (
        <>
            <CardContent>
                <h2>{ qn.question }</h2>
                <FormControl component="fieldset" error={error}>
                    <RadioGroup value={value} onChange={handleChange}>
                        { qn.options.map((option, index) => (<FormControlLabel key={index} value={option.value} control={<Radio color="primary" />} label={option.option} />)) }
                    </RadioGroup>
                    { error && <FormHelperText>{helperText}</FormHelperText>}
                </FormControl>
            </CardContent>
            <CardActions>
                {qn.id !== 1 && <Button variant="contained" color='secondary' size="medium" onClick={prev}>
                    Edellinen
                </Button>}
                <Button variant="contained" color='primary' size="medium" onClick={next}>
                    Seuraava
                </Button>
            </CardActions>
        </>
    )
}

export default RadioQuestion
