import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Contact from './Contact'

const Done = ({ hide, success, notifications, contacts }) => {
    return (
        <>
            <CardMedia
                component='img'
                image={success.imgUrl}
                title='Sinustako KuntoKruunu-yrittäjä?'
            />
            <CardContent className='success'>
                <h2>{success.title}</h2>
                <p>{success.message}</p>
                {notifications.length > 0 && <p className='notif-pre'>{success.notificationPre}</p>}
                {notifications && <ul>{
                    notifications.map((notification) => (
                        <li key={notification.id}>{notification.notification}</li>
                    ))
                    }</ul>}
                {contacts.length > 0 && <div className='contacts'>
                    {contacts.map((contact, index) => (
                        <Contact key={index} contact={contact} />
                    ) )}
                </div> }
                <div>
                    <p>Voit olla meihin yhteydessä myös lomakkeen kautta!</p>
                    <Button href={success.contactFormLink} className='contact-button' variant="contained" color='primary'>Ota yhteyttä</Button>
                    <Button href={success.readMoreLink} className='read-more-button' variant="contained" color='secondary'>Lue lisää</Button>
                </div>
            </CardContent>
            <CardActions>
                <Button onClick={hide} variant="outlined" color='primary' size="medium">
                Aloita testi alusta
                </Button>
            </CardActions>
        </>
    )
}

export default Done
