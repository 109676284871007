import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Slider from '@material-ui/core/Slider'
import { useState } from 'react'

const SliderQuestion = ({ qn, updCurrent, handleNotifications }) => {
    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const prev = () => {
        updCurrent('prev')
        handleNotifications('remove', qn.id, null)
    }
    const next = () => {
        const target = qn.options.find(option => option.value === '' + value)
        if(target && target.notification) {
            handleNotifications('add', qn.id, target.notification)
        } else {
            handleNotifications('remove', qn.id, null)
        }
        updCurrent('next')
    }

    return (
        <>
            <CardContent>
                <h2>{ qn.question }</h2>
                <Slider value={value} marks min={0} max={5} onChange={handleChange} />
                <ul className="slider-labels">
                    <li>Ei kiitos, haluan nukkua yöni rauhassa</li>
                    <li>Se kuuluu asiaan</li>
                </ul>
            </CardContent>
            <CardActions>
                <Button variant="contained" color='secondary' size="medium" onClick={prev}>
                    Edellinen
                </Button>
                <Button variant="contained" color='primary' size="medium" onClick={next}>
                    Seuraava
                </Button>
            </CardActions>
        </>
    )
}

export default SliderQuestion
