const Contact = ({ contact }) => {
    return (
        <div className='contact'>
            <p className='contact-name'>{contact.name}</p>
            <p className='contact-title'>{contact.title}</p>
            <p className='contact-email'><a href={`mailto:${contact.email}`}>{contact.email}</a></p>
            <p><a href={`tel:${contact.mobile}`}>{contact.mobilePretty}</a></p>
        </div>
    )
}

export default Contact
