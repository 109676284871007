import { CSSTransition } from 'react-transition-group'
import Card from '@material-ui/core/Card'
import RadioQuestion from './RadioQuestion'
import SliderQuestion from './SliderQuestion'
import SelectQuestion from './SelectQuestion'
import Welcome from './Welcome'
import Fail from './Fail'
import Done from './Done'
import { useState, useRef } from 'react'

const CardAnimate = props => {
    const nodeRef = useRef(null)
    const [show, setShow] = useState(true)

    return (
        <CSSTransition
            timeout={400}
            in={props.current ? props.current === props.question.id : show}
            appear={true}
            exit={true}
            classNames="kk-card"
            nodeRef={nodeRef}
            onExited={props.start}
        >
            <Card ref={nodeRef} raised className='kk-card-base'>
                { props.target === 'welcome' && <Welcome hide={() => setShow(false)} /> }
                { props.target === 'failed' && <Fail fail={props.fail} hide={() => setShow(false)} /> }
                { props.target === 'finished' && <Done contacts={props.contacts} success={props.success} notifications={props.notifications} hide={() => setShow(false)} /> }
                { props.target === 'radio' && <RadioQuestion qn={props.question} updCurrent={props.updCurrent} handleNotifications={props.handleNotifications} /> }
                { props.target === 'slider' && <SliderQuestion qn={props.question} updCurrent={props.updCurrent} handleNotifications={props.handleNotifications} /> }
                { props.target === 'select' && <SelectQuestion qn={props.question} updCurrent={props.updCurrent} /> }
            </Card>
        </CSSTransition>
    )
}

export default CardAnimate
