import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useState } from 'react'

const MenuBar = () => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }


    return (
      <AppBar color="default" position="static">
        <Toolbar>
          <Box className="menu-logo">
            <img src="https://www.kuntokruunu.fi/wp-content/uploads/2020/07/LOGO.png" alt="" />
          </Box>
          <IconButton aria-controls="simple-menu" aria-haspopup="true" edge="start" color="inherit" aria-label="menu" onClick={handleClick}>
            <MenuIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem className="menu-btn" component={Button} href="https://www.kuntokruunu.fi">KuntoKruunu Etusivu</MenuItem>
            <MenuItem  className="menu-btn" component={Button} href="https://www.kuntokruunu.fi/aloita-kuntokruunu-yrittajana/">Lue lisää KuntoKruunu-yrittäjyydestä</MenuItem>
            <MenuItem  className="menu-btn" component={Button} href="https://www.kuntokruunu.fi/aloita-kuntokruunu-yrittajana/#contact">Ota yhteyttä</MenuItem>
            <MenuItem  className="menu-btn" component={Button} href="https://testi.kuntokruunu.fi">Aloita testi alusta</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    )
}

export default MenuBar


// target="_blank" rel="noopener noreferrer"