import Paper from '@material-ui/core/Paper'

const AppFooter = () => {
    return (
        <Paper elevation={3} className="app-footer" >
            <p>Toteutus: <a href="https://www.creativedigiworks.fi" target="_blank" rel="noopener noreferrer">Creative Digiworks</a></p>    
        </Paper>
    )
}

export default AppFooter
