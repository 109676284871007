import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'

const Welcome = ({ hide }) => {
    return (
        <>
            <CardMedia
                component='img'
                image='https://www.kuntokruunu.fi/wp-content/uploads/2021/06/tee-testi-kuntokruunu.jpg'
                title='Sinustako KuntoKruunu-yrittäjä?'
            />
            <CardContent>
                <h2>Sinustako KuntoKruunu-yrittäjä?!</h2>
                <p>Tämän testin avulla selvität, olisiko sinusta KuntoKruunu-yrittäjäksi!</p>
            </CardContent>
            <CardActions>
                <Button onClick={hide} variant="contained" color='primary' size="medium">
                Aloita testi
                </Button>
            </CardActions>
        </>
    )
}

export default Welcome
