import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'

const Fail = ({ hide, fail }) => {
    return (
        <>
            <CardMedia
                component='img'
                image={fail.imgUrl}
                title='Sinustako KuntoKruunu-yrittäjä?'
            />
            <CardContent>
                <h2>{fail.title}</h2>
                <p>{fail.message}</p>
            </CardContent>
            <CardActions>
                <Button onClick={hide} variant="contained" color='primary' size="medium">
                Aloita testi alusta
                </Button>
            </CardActions>
        </>
    )
}

export default Fail
