import './App.css'
import CardAnimate from './components/CardAnimate'
import Container from '@material-ui/core/Container'
import LinearProgress from '@material-ui/core/LinearProgress'
import MenuBar from './components/MenuBar'
import AppFooter from './components/AppFooter'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { useState } from 'react'
import ReactGA from 'react-ga'

ReactGA.initialize('UA-104380131-1')
ReactGA.pageview(window.location.pathname + window.location.search)

const kuntokruunu = createMuiTheme({
  palette: {
    primary: {
      main: '#e30614',
    },
    secondary: {
      main: '#ffba00',
    }
  }
})

function App() {
  const [welcome, setWelcome] = useState(true)
  const [started, setStarted] = useState(false)
  const [finished, setFinished] = useState(false)
  const [current, setCurrent] = useState(1)
  const [progress, setProgress] = useState(0)
  const [contacts] = useState([
    {
      name: 'Jukka Halttunen',
      title: 'Yrittäjä',
      email: 'jukka.halttunen@kuntokruunu.fi',
      mobile: '+358408125121',
      mobilePretty: '040 812 5121'
    },
    {
      name: 'Petteri Lehtonen',
      title: 'Järjestelmäasiantuntija',
      email: 'petteri.lehtonen@kuntokruunu.fi',
      mobile: '+358405543436',
      mobilePretty: '040 554 3436'
    }
  ])
  const [questions] = useState([
    {
      id: 1,
      question: 'Oletko miettinyt kuntosalin avaamista?',
      type: 'radio',
      options: [
        {option: 'Olen!', value: '1', next: 'next'},
        {option: 'En', value: '2', next: 'end'}
      ],
    },
    {
      id: 2,
      question: 'Onko sinulla salin sijaintipaikka jo mielessä?',
      type: 'radio',
      options: [
        {option: 'Kyllä!', value: '1', next: 'next'},
        {option: 'Ei', value: '2', next: 'next'}
      ],
    },
    {
      id: 3,
      question: 'Onko joku näistä mieluisa paikkakunta?',
      type: 'select',
      options: [
        {option: 'Heinola', value: '1', next: 'next'},
        {option: 'Lohja', value: '2', next: 'next'},
        {option: 'Mäntsälä', value: '3', next: 'next'},
        {option: 'Nurmijärvi', value: '4', next: 'next'},
        {option: 'Riihimäki', value: '5', next: 'next'},
        {option: 'Vantaa', value: '6', next: 'next'},
        {option: 'Vihti', value: '7', next: 'next'},
        {option: 'Ei mikään yllä olevista', value: '8', next: 'next'},
      ],
    },
    {
      id: 4,
      question: 'Haluatko tarjota asiakkaillesi pelkän kuntosalin vai myös virtuaalista ryhmäliikuntaa?',
      type: 'radio',
      options: [
        {option: 'Pelkkä kuntosali', value: '1', next: 'next'},
        {option: 'Molemmat', value: '2', next: 'next'},
        {option: 'En tiedä', value: '3', next: 'next'}
      ],
    },
    {
      id: 5,
      question: 'Haluatko uhrata vapaa-aikaasi soittelemalla potentiaalisille asiakkaille vai antaa automatiikan hoitaa homman puolestasi?',
      type: 'radio',
      options: [
        {option: 'Automatiikka hoitaa', value: '1', next: 'next'},
        {option: 'Haluan soitella!', value: '2', next: 'next', notification: 'KuntoKruunu-konseptiin ei kuulu potentiaalisten asiakkaiden häirintä puhelimitse.'},
      ],
    },
    {
      id: 6,
      question: 'Haluatko itse suunnitella ja hallita markkinointia vai jätätkö sen mielummin osaaviin käsiin?',
      type: 'radio',
      options: [
        {option: 'Jätän sen mielelläni ammattilaisille ja käytän ajan tehokkaasti johonkin muuhun', value: '1', next: 'next'},
        {option: 'Haluan hoitaa markkinointia itse', value: '2', next: 'next', notification: 'KuntoKruunu-konseptin yksi peruspilareista on yhtenäinen markkinointi, johon KuntoKruunu-yrittäjät sitoutuvat.'}
      ],
    },
    {
      id: 7,
      question: 'Yritystoimintaan liittyy aina riskejä. Miten suhtaudut riskinottoon?',
      type: 'slider',
      options: [
        {option: 'Ei kiitos, haluan nukkua yöni rauhassa', value: '0', next: 7, notification: 'Riskit on pyritty minimoimaan käytännössä testatulla toimintatavalla, mutta yrittäjälle jää aina vastuu yritystoiminnastaan.'},
        {option: 'Se kuuluu asiaan', value: '5', next: 7}
      ],
    },
    {
      id: 8,
      question: 'Liiketoiminnan aloittaminen vaatii rahoitusta. Tarvitsetko tukea rahoituksen hankkimiseen?',
      type: 'radio',
      options: [
        {option: 'Kyllä kiitos!', value: '1', next: 'next'},
        {option: 'En tiedä, ehkä', value: '2', next: 'next'},
        {option: 'Ei tartte auttaa!', value: '3', next: 'next'}
      ],
    },
    {
      id: 9,
      question: 'Haluatko keksiä pyörän uudelleen vai liittyä KuntoKruunu-konseptiin ja saada käyttöösi turvallisen ja käytännössä testatun tavan toimia kuntosaliyrittäjänä?',
      type: 'radio',
      options: [
        {option: 'Ai että haluanko?!', value: '1', next: 'finish'},
        {option: 'Pärjään kyllä omillani, opin sitten kantapään kautta', value: '2', next: 'end'},
      ],
    }
  ])
  const [notifications, setNotifications] = useState([])
  const [failed, setFailed] = useState(false)
  const [fail] = useState({
    title: 'Kiitos testin tekemisestä!',
    message: 'Näyttää siltä, että meidän kannattaa jatkaa matkaa omilla tahoilla. Jos asia tulee myöhemmin ajankohtaiseksi, palaa asiaan.',
    imgUrl: 'https://images.unsplash.com/photo-1579373903781-fd5c0c30c4cd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=640&q=80'
  })
  const [success] = useState({
    title: 'Kääritään hihat ja ruvetaan hommiin!',
    message: 'Olet hyvä ehdokas KuntoKruunu-yrittäjäksi! Ota yhteyttä niin keskustellaan tarkemmin! Yhteydenotto ei sido sinua mihinkään.',
    notificationPre: 'Huomioita koskien vastauksiasi:',
    imgUrl: 'https://images.unsplash.com/photo-1523287562758-66c7fc58967f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=640&q=80',
    readMoreLink: 'https://www.kuntokruunu.fi/aloita-kuntokruunu-yrittajana/',
    contactFormLink: 'https://www.kuntokruunu.fi/aloita-kuntokruunu-yrittajana/#contact',
  })

  const updCurrent = id => {
    if(id === 'next') setCurrent((state) => {
      updProgress(state + 1)
      return state + 1
    })
    if(id === 'prev') {
      setCurrent((state) => {
        updProgress(state - 1)
        return state - 1
      })
    }
    if(id === 'end') {
      setCurrent((state) => {
        updProgress(0)
        setFailed(true)
        setFinished(false)
        setStarted(false)
        return 1
      })
    }
    if(id === 'finish') {
      setCurrent((state) => {
        setFinished(true)
        setProgress(100)
        setFailed(false)
        setStarted(false)
        ReactGA.event({category: 'Franchising', action: 'Test done', label: 'KuntoKruunu-yrittaja', nonInteraction: true})
        return 1
      })
    }
  }

  const updProgress = (cur) => {
    const percent = (cur-1)/questions.length*100
    setProgress(percent)
  }

  const handleNotifications = (action, id, notification) => {
    if(action === 'add') {
      const data = {
        id,
        notification
      }
      setNotifications([...notifications, data])
    }
    if(action === 'remove') {
      const noti = notifications.filter(notification => notification.id !== id)
      setNotifications(noti)
    }
  }

  const start = () => {
    setFinished(false)
    setStarted(true)
    setWelcome(false)
    setNotifications([])
    setProgress(0)
  }

  return (
    <ThemeProvider theme={kuntokruunu}>
      <div>
        <MenuBar />
        <Container className="kuntokruunu-test">
          {welcome && <CardAnimate target={'welcome'} start={start} />}
          {failed && <CardAnimate target={'failed'} start={start} fail={fail} />}
          {(started || finished) &&  <LinearProgress variant="determinate" value={progress} />}
          {finished && <CardAnimate target={'finished'} notifications={notifications} start={start} success={success} contacts={contacts} />}
          {started && questions.map(qn => (<CardAnimate key={qn.id} question={qn} target={qn.type} current={current} updCurrent={updCurrent} handleNotifications={handleNotifications} />))}
        </Container>
        <AppFooter />
      </div>
    </ThemeProvider>
  );
}

export default App;
