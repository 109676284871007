import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useState } from 'react'

const SelectQuestion = ({ qn, updCurrent }) => {
    const [value, setValue] = useState('')
    const [error, setError] = useState(false)
    const [helperText, setHelperText] = useState('')

    const handleChange = (event) => {
        setValue(event.target.value)
        setHelperText(' ');
        setError(false);
    }

    const prev = () => {
        updCurrent('prev')
    }
    const next = () => {
        if(!value) {
            setHelperText('Valitse jokin vaihtoehdoista!')
            setError(true)
            return
        }
        updCurrent('next')
    }

    return (
        <>
            <CardContent>
                <h2>{ qn.question }</h2>
                {/* <Slider value={value} marks min={0} max={5} onChange={handleChange} /> */}
                <InputLabel id="select-label" >Valitse</InputLabel>
                <Select
                    labelId="select-label"
                    value={value}
                    onChange={handleChange}
                    native
                >
                    <option aria-label="None" value="" />
                    {qn.options.map((option, index) => (<option key={index} value={option.value}>{option.option}</option>))}
                </Select>
                { error && <FormHelperText>{helperText}</FormHelperText>}
            </CardContent>
            <CardActions>
                <Button variant="contained" color='secondary' size="medium" onClick={prev}>
                    Edellinen
                </Button>
                <Button variant="contained" color='primary' size="medium" onClick={next}>
                    Seuraava
                </Button>
            </CardActions>
        </>
    )
}

export default SelectQuestion
